import Lottie from "lottie-react";
import animationData from "../../static/animations/spinner.json";
import Layout from "./Layout";

export default function LoadingScreen() {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center gap-2 w-full h-full p-8">
        <h1 className="font-semibold text-4xl text-[#F17C36] text-center">Aguarde...</h1>
        <Lottie loop={true} autoplay={true} animationData={animationData} />
        <p className="font-semibold text-3xl text-[#338F8C] text-center">
          Estamos carregando suas informações!
        </p>
      </div>
    </Layout>
  );
}
