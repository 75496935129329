import { api } from "../api";

export const getMeetInfo = async (userId) => {
  try {
    const { data } = await api.get(`/workout/info/${userId}`);

    return data;
  } catch (error) {
    console.log("🚀 ~ error:", error);
  }
};
