import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import NotFound from "../components/screens/NotFound";
import NotWorkout from "../components/screens/NotWorkout";
import UserNotFound from "../components/screens/UserNotFound";
import { ClassEnded } from "../components/screens/ClassEnded";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/:id" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/not-workout" element={<NotWorkout />} />
      <Route path="/user-not-found" element={<UserNotFound />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="/class-ended" element={<ClassEnded />} />
    </Routes>
  );
}
