import { Constants, MeetingProvider } from "@videosdk.live/react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import LoadingScreen from "./components/screens/LoadingScreen";
import WaitingToJoinScreen from "./components/screens/WaitingToJoinScreen";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { getMeetInfo } from "./services/meetService";

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [meetEnding, setMeetEnding] = useState("");

  const meetingMode = Constants.modes.CONFERENCE;

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (id.length) {
      (async () => {
        try {
          const dataWorkout = await getMeetInfo(id);

          if (!dataWorkout) {
            navigate("/user-not-found");
            return;
          }

          const { token, roomId, name, nextClass, existNextClass, endClass } =
            dataWorkout || {};

          if (token && roomId && name && endClass) {
            setToken(token);
            setMeetingId(roomId);
            setParticipantName(name);
            setMeetEnding(endClass.toString());

            return;
          }

          if (nextClass) {
            navigate("/not-workout", { state: { nextClass, existNextClass } });
            return;
          }

          navigate("/not-workout", { state: { existNextClass } });
          return;
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      navigate("/not-found");
    }
  }, []);

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <MeetingAppProvider
        selectedMic={selectedMic}
        selectedWebcam={selectedWebcam}
        initialMicOn={micOn}
        initialWebcamOn={webcamOn}
      >
        <MeetingProvider
          config={{
            meetingId,
            micEnabled: micOn,
            webcamEnabled: webcamOn,
            name: participantName ? participantName : "TestUser",
            mode: meetingMode,
            maxResolution: "hd",
            multiStream: true,
          }}
          token={token}
          reinitialiseMeetingOnConfigChange={true}
          joinWithoutUserInteraction={true}
        >
          {isMeetingStarted ? (
            <MeetingContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setMeetingStarted={setMeetingStarted}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              meetEnding={meetEnding}
            />
          ) : isMeetingLeft ? (
            <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
          ) : (
            <>
              <WaitingToJoinScreen />
              <JoiningScreen
                participantName={participantName}
                setMicOn={setMicOn}
                micEnabled={micOn}
                webcamEnabled={webcamOn}
                setSelectedMic={setSelectedMic}
                setSelectedWebcam={setSelectedWebcam}
                setWebcamOn={setWebcamOn}
                handleInitMeet={() => {
                  setMeetingStarted(true);
                }}
                startMeeting={isMeetingStarted}
                setIsMeetingLeft={setIsMeetingLeft}
                meetingMode={meetingMode}
              />
            </>
          )}
        </MeetingProvider>
      </MeetingAppProvider>
    </>
  );
};

export default Home;
