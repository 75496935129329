import Lottie from "lottie-react";
import animationData from "../../static/animations/user_not_found.json";
import Layout from "./Layout";

export default function UserNotFound() {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center w-full h-full p-8">
        <h1 className="font-semibold text-4xl text-[#338F8C] text-center">
          Usuário não encontrado
        </h1>
        <div className="w-80 h-80">
          <Lottie
            loop={true}
            autoplay={true}
            animationData={animationData}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <p className="font-semibold text-3xl text-[#F17C36] text-center">
          Tente novamente.
        </p>
      </div>
    </Layout>
  );
}
