import * as React from "react";
const PersonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="M21.6497 20.625C21.5839 20.739 21.4892 20.8337 21.3751 20.8995C21.2611 20.9654 21.1317 21 21 21H3.00002C2.86844 20.9999 2.7392 20.9651 2.62529 20.8992C2.51139 20.8333 2.41682 20.7386 2.35108 20.6247C2.28535 20.5107 2.25076 20.3814 2.25079 20.2498C2.25083 20.1182 2.28548 19.9889 2.35127 19.875C3.77909 17.4065 5.9794 15.6365 8.54721 14.7975C7.27706 14.0414 6.29022 12.8892 5.73826 11.5179C5.1863 10.1467 5.09973 8.63211 5.49184 7.20688C5.88396 5.78165 6.73308 4.52454 7.9088 3.6286C9.08452 2.73266 10.5218 2.24744 12 2.24744C13.4782 2.24744 14.9155 2.73266 16.0913 3.6286C17.267 4.52454 18.1161 5.78165 18.5082 7.20688C18.9003 8.63211 18.8137 10.1467 18.2618 11.5179C17.7098 12.8892 16.723 14.0414 15.4528 14.7975C18.0207 15.6365 20.221 17.4065 21.6488 19.875C21.7147 19.9889 21.7496 20.1182 21.7497 20.2499C21.7499 20.3815 21.7154 20.5109 21.6497 20.625Z"
      fill="#338F8C"
    />
  </svg>
);
export default PersonIcon;
