import Lottie from "lottie-react";
import animationData from "../../static/animations/page_not_found.json";
import Layout from "./Layout";

export default function NotFound() {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center gap-6 w-full h-full p-8">
        <div className="w-80 h-80">
          <Lottie
            loop={true}
            autoplay={true}
            animationData={animationData}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <p className="font-semibold text-3xl text-[#F17C36]">
          Página não encontrada.
        </p>
      </div>
    </Layout>
  );
}
