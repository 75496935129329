import Layout from "./Layout";

export function ClassEnded({}) {
  return (
    <Layout>
      <div className=" h-screen flex flex-col flex-1 items-center justify-center px-4">
        <h1 className="text-5xl text-[#F17C36] font-semibold text-center    ">
          Sua aula foi concluída
        </h1>
        <div className="mt-12 flex flex-col align-middle justify-center items-center text-center">
            <p className="font-normal text-3xl text-[#338F8C]">
            Obrigado por treinar com a Domus!
            </p>
            <p className="font-normal text-3xl text-[#338F8C]">
            Você já pode fechar esta página
            </p>
        </div>
      </div>
    </Layout>
  );
}
