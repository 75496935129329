import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import LogoDomus from "../../assets/images/logo/logo-domus-lg.png";

export default function Layout({ children }) {
  return (
    <>
      <div className="flex flex-col w-full h-screen bg-white relative">
        <div className="w-28 h-28 absolute left-3 top-3">
          <img src={LogoDomus} alt="logo-domus" className="object-cover w-3/4" />
        </div>
        <Transition
          appear
          show
          as={Fragment}
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {children}
        </Transition>
      </div>
    </>
  );
}
