import React from "react";
import { useMeetingAppContext } from "../MeetingAppContextDef";
import { ParticipantView } from "./ParticipantView";

const MemoizedParticipant = React.memo(
  ParticipantView,
  (prevProps, nextProps) => {
    return prevProps.participantId === nextProps.participantId;
  }
);

function ParticipantGrid({ participantIds, isPresenting }) {
  const { sideBarMode } = useMeetingAppContext();
  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  return (
    <div
      className={`flex flex-col md:flex-row flex-grow m-0 items-center justify-center ${
        participantIds.length < 2 && !sideBarMode && !isPresenting
          ? "md:px-0 md:py-0"
          : "md:px-0"
      }`}
    >
      <div className="flex flex-col w-full h-full relative">
        <div
          className={`flex flex-1  ${
            isPresenting
              ? participantIds.length === 1
                ? "justify-start items-start"
                : "items-center justify-center"
              : "items-center justify-center"
          }`}
        >
          {participantIds.map((participantId, index) => {
            const isLocal = index === 0;

            const containerClass = isLocal
              ? " w-40 h-24 rounded-md absolute bottom-20 right-6 z-10"
              : "flex-1 w-full h-full items-center justify-center";

            return (
              <div
                key={`participant_${participantId}_${index}`}
                className={`flex items-center justify-center overflow-clip overflow-hidden p-0 ${containerClass} text-white`}
              >
                <MemoizedParticipant participantId={participantId} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const MemoizedParticipantGrid = React.memo(
  ParticipantGrid,
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.participantIds) ===
        JSON.stringify(nextProps.participantIds) &&
      prevProps.isPresenting === nextProps.isPresenting
    );
  }
);
