import { CheckCircleIcon } from "@heroicons/react/outline";
import { createPopper } from "@popperjs/core";
import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import MicOnIcon from "../../icons/Bottombar/MicOnIcon";
import WebcamOnIcon from "../../icons/Bottombar/WebcamOnIcon";
import MicOffIcon from "../../icons/MicOffIcon";
import WebcamOffIcon from "../../icons/WebcamOffIcon";
import ConfirmBox from "../ConfirmBox";
import SettingDialogueBox from "../SettingDialogueBox";

export function JoiningScreen({
  participantName,
  setSelectedMic,
  setSelectedWebcam,
  handleInitMeet,
  micEnabled,
  webcamEnabled,
  setWebcamOn,
  setMicOn,
}) {
  const [setting, setSetting] = useState("video");
  const [{ webcams, mics }, setDevices] = useState({
    devices: [],
    webcams: [],
    mics: [],
  });

  const [videoTrack, setVideoTrack] = useState(null);

  const [dlgMuted, setDlgMuted] = useState(false);
  const [dlgDevices, setDlgDevices] = useState(false);

  const videoPlayerRef = useRef();
  const popupVideoPlayerRef = useRef();
  const popupAudioPlayerRef = useRef();

  const videoTrackRef = useRef();
  const audioTrackRef = useRef();
  const audioAnalyserIntervalRef = useRef();

  const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);

  const [audioTrack, setAudioTrack] = useState(null);

  const { participants } = useMeeting();

  useEffect(() => {
    if (participants?.size > 1 && handleInitMeet) {
      handleInitMeet();
    }
  }, [participants, handleInitMeet]);

  const handleClickOpen = () => {
    setSettingDialogueOpen(true);
  };

  const handleClose = (value) => {
    setSettingDialogueOpen(false);
  };

  const isMobile = useIsMobile();

  const webcamOn = useMemo(() => !!videoTrack, [videoTrack]);
  const micOn = useMemo(() => !!audioTrack, [audioTrack]);

  const _handleTurnOffWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
      setWebcamOn(false);
    }
  };
  const _handleTurnOnWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (!videoTrack) {
      getDefaultMediaTracks({ mic: false, webcam: true });
      setWebcamOn(true);
    }
  };

  const _toggleWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (videoTrack) {
      _handleTurnOffWebcam();
    } else {
      _handleTurnOnWebcam();
    }
  };
  const _handleTurnOffMic = () => {
    const audioTrack = audioTrackRef.current;

    if (audioTrack) {
      audioTrack.stop();

      setAudioTrack(null);
      setMicOn(false);
    }
  };
  const _handleTurnOnMic = () => {
    const audioTrack = audioTrackRef.current;

    if (!audioTrack) {
      getDefaultMediaTracks({ mic: true, webcam: false });
      setMicOn(true);
    }
  };
  const _handleToggleMic = () => {
    const audioTrack = audioTrackRef.current;

    if (audioTrack) {
      _handleTurnOffMic();
    } else {
      _handleTurnOnMic();
    }
  };

  const changeWebcam = async (deviceId) => {
    const currentvideoTrack = videoTrackRef.current;

    if (currentvideoTrack) {
      currentvideoTrack.stop();
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId },
    });
    const videoTracks = stream.getVideoTracks();

    const videoTrack = videoTracks.length ? videoTracks[0] : null;

    setVideoTrack(videoTrack);
  };
  const changeMic = async (deviceId) => {
    const currentAudioTrack = audioTrackRef.current;
    currentAudioTrack && currentAudioTrack.stop();
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId },
    });
    const audioTracks = stream.getAudioTracks();

    const audioTrack = audioTracks.length ? audioTracks[0] : null;
    clearInterval(audioAnalyserIntervalRef.current);

    setAudioTrack(audioTrack);
  };

  const getDefaultMediaTracks = async ({ mic, webcam, firstTime }) => {
    if (mic) {
      const audioConstraints = {
        audio: true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        audioConstraints
      );
      const audioTracks = stream.getAudioTracks();

      const audioTrack = audioTracks.length ? audioTracks[0] : null;

      setAudioTrack(audioTrack);
      if (firstTime) {
        setSelectedMic({
          id: audioTrack?.getSettings()?.deviceId,
        });
      }
    }

    if (webcam) {
      const videoConstraints = {
        video: {
          width: 1280,
          height: 720,
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        videoConstraints
      );
      const videoTracks = stream.getVideoTracks();

      const videoTrack = videoTracks.length ? videoTracks[0] : null;
      setVideoTrack(videoTrack);
      if (firstTime) {
        setSelectedWebcam({
          id: videoTrack?.getSettings()?.deviceId,
        });
      }
    }
  };

  async function startMuteListener() {
    const currentAudioTrack = audioTrackRef.current;

    if (currentAudioTrack) {
      if (currentAudioTrack.muted) {
        setDlgMuted(true);
      }

      currentAudioTrack.addEventListener("mute", (ev) => {
        setDlgMuted(true);
      });
    }
  }

  const getDevices = async ({ micEnabled, webcamEnabled }) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const webcams = devices.filter((d) => d.kind === "videoinput");
      const mics = devices.filter((d) => d.kind === "audioinput");

      const hasMic = mics.length > 0;
      const hasWebcam = webcams.length > 0;

      setDevices({ webcams, mics, devices });

      if (hasMic) {
        startMuteListener();
      }

      getDefaultMediaTracks({
        mic: hasMic && micEnabled,
        webcam: hasWebcam && webcamEnabled,
        firstTime: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    audioTrackRef.current = audioTrack;

    startMuteListener();

    return () => {
      const currentAudioTrack = audioTrackRef.current;
      currentAudioTrack && currentAudioTrack.stop();
      audioTrackRef.current = null;
    };
  }, [audioTrack]);

  useEffect(() => {
    videoTrackRef.current = videoTrack;

    if (videoTrack) {
      const videoSrcObject = new MediaStream([videoTrack]);

      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = videoSrcObject;
        videoPlayerRef.current.play();
      }

      setTimeout(() => {
        if (popupVideoPlayerRef.current) {
          popupVideoPlayerRef.current.srcObject = videoSrcObject;
          popupVideoPlayerRef.current.play();
        }
      }, 1000);
    } else {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = null;
      }
      if (popupVideoPlayerRef.current) {
        popupVideoPlayerRef.current.srcObject = null;
      }
    }
  }, [videoTrack, setting, settingDialogueOpen]);

  useEffect(() => {
    getDevices({ micEnabled, webcamEnabled });
  }, []);

  const ButtonWithTooltip = ({ onClick, onState, OnIcon, OffIcon, mic }) => {
    const [tooltipShow, setTooltipShow] = useState(false);
    const btnRef = useRef();
    const tooltipRef = useRef();

    const openTooltip = () => {
      createPopper(btnRef.current, tooltipRef.current, {
        placement: "top",
      });
      setTooltipShow(true);
    };
    const closeTooltip = () => {
      setTooltipShow(false);
    };

    return (
      <>
        <div>
          <button
            ref={btnRef}
            onMouseEnter={openTooltip}
            onMouseLeave={closeTooltip}
            onClick={onClick}
            className={`rounded-full min-w-auto w-9 h-9 flex items-center justify-center ${
              onState ? "bg-white" : "bg-red-650 text-white"
            }`}
          >
            {onState ? (
              <OnIcon fillcolor={onState ? "#050A0E" : "#fff"} />
            ) : (
              <OffIcon fillcolor={onState ? "#050A0E" : "#fff"} />
            )}
          </button>
        </div>
        <div
          style={{ zIndex: 999 }}
          className={`${
            tooltipShow ? "" : "hidden"
          } overflow-hidden flex flex-col items-center justify-center pb-1.5`}
          ref={tooltipRef}
        >
          <div className={"rounded-md p-1.5 bg-black "}>
            <p className="text-base text-white ">
              {onState
                ? `Desligar ${mic ? "mic" : "webcam"}`
                : `Ligar ${mic ? "mic" : "webcam"}`}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="absolute right-0 top-0 bottom-0">
      <div className="overflow-y-auto flex flex-col w-72 h-screen justify-end">
        <div className="flex flex-col md:flex-row items-center justify-center p-2">
          <div className="container">
            <div className="flex items-center justify-center w-full">
              <div className="flex items-center justify-center ">
                <div className="relative w-full">
                  <div className="flex items-center justify-center w-full relative" style={{ height: "20vh" }}>
                    <video
                      autoPlay
                      playsInline
                      muted
                      ref={videoPlayerRef}
                      controls={false}
                      style={{
                        backgroundColor: "#1c1c1c",
                      }}
                      className={
                        "rounded-md h-full w-full object-cover flex items-center justify-center flip -translate-x-1"
                      }
                    />
                    {!isMobile && (
                      <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                        {!webcamOn ? (
                          <p className="text-xl xl:text-lg 2xl:text-xl text-white">
                            Camera desligada
                          </p>
                        ) : null}
                      </div>
                    )}
                    {settingDialogueOpen && (
                      <SettingDialogueBox
                        open={settingDialogueOpen}
                        onClose={handleClose}
                        popupVideoPlayerRef={popupVideoPlayerRef}
                        popupAudioPlayerRef={popupAudioPlayerRef}
                        changeWebcam={changeWebcam}
                        changeMic={changeMic}
                        setting={setting}
                        setSetting={setSetting}
                        webcams={webcams}
                        mics={mics}
                        setSelectedMic={setSelectedMic}
                        setSelectedWebcam={setSelectedWebcam}
                        videoTrack={videoTrack}
                        audioTrack={audioTrack}
                      />
                    )}
                    <div className="absolute xl:bottom-6 bottom-4 left-0 right-0">
                      <div className="container grid grid-flow-col space-x-4 items-center justify-center md:-m-2">
                        <ButtonWithTooltip
                          onClick={_handleToggleMic}
                          onState={micOn}
                          mic={true}
                          OnIcon={MicOnIcon}
                          OffIcon={MicOffIcon}
                        />
                        <ButtonWithTooltip
                          onClick={_toggleWebcam}
                          onState={webcamOn}
                          mic={false}
                          OnIcon={WebcamOnIcon}
                          OffIcon={WebcamOffIcon}
                        />
                      </div>
                    </div>
                  </div>
                  {!isMobile && (
                    <div
                      className=" rounded cursor-pointer bg-[#338F8C] py-1 my-2"
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      <div className="flex flex-row items-center justify-center m-1">
                        <button className="text-white">
                          <CheckCircleIcon className="h-5 w-5" />
                        </button>
                        <p className="text-base text-white ml-1">
                          Configurações
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmBox
        open={dlgMuted}
        successText="OKAY"
        onSuccess={() => {
          setDlgMuted(false);
        }}
        title="Microfone desligado"
        subTitle="Por favor, ligue o microfone para continuar."
      />
      <ConfirmBox
        open={dlgDevices}
        successText="DISMISS"
        onSuccess={() => {
          setDlgDevices(false);
        }}
        title="Microfone ou webcam não detectado"
        subTitle="Por favor, conecte um microfone ou webcam para continuar."
      />
    </div>
  );
}
