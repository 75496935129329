import Layout from "./Layout";

export function LeaveScreen({ setIsMeetingLeft }) {
  return (
    <Layout>
      <div className=" h-screen flex flex-col flex-1 items-center justify-center">
        <h1 className="text-4xl text-[#F17C36]">
          Você saiu da chamada.
        </h1>
        <div className="mt-12">
          <button
            className="`w-full bg-[#338F8C] text-white px-16 py-3 rounded-lg text-sm"
            onClick={() => {
              setIsMeetingLeft(false);
            }}
          >
            Retornar à aula
          </button>
        </div>
      </div>
    </Layout>
  );
}
