import Lottie from "lottie-react";
import { useLocation } from "react-router-dom";
import animationData from "../../static/animations/not_workout.json";
import Layout from "./Layout";

export default function NotWorkout() {
  const { nextClass, existNextClass } = useLocation().state;

  const getNextClassStr = () => {
    if (!nextClass) return "";

    const nextClassDate = new Date(nextClass).getDay() - new Date().getDay();
    const nextClassTime = new Date(nextClass).getTime() - new Date().getTime();

    const nextClassHours = Math.floor(nextClassTime / 1000 / 60 / 60);
    const nextClassMinutes = Math.floor(
      (nextClassTime / 1000 / 60 / 60 - nextClassHours) * 60
    );

    const nextClassDayStr = nextClassDate > 0 ? `${nextClassDate}d ` : "";
    const nextClassHoursStr = nextClassHours > 0 ? `${nextClassHours}h ` : "";
    const nextClassMinutesStr = `${nextClassMinutes}min`;

    return ` ${nextClassDayStr ?? " "}${
      nextClassHoursStr ?? " "
    }${nextClassMinutesStr}`;
  };

  setTimeout(() => {
    if (getNextClassStr() !== "0") window.location.reload();
    else window.history.back()
  }, 1000 * 60);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center w-full h-full p-8">
        <h1 className="font-semibold text-2xl text-[#338F8C] text-center">
          Você não tem aula neste momento.
        </h1>
        <div className="w-60 h-60 flex flex-col items-center justify-center">
          <Lottie
            loop={true}
            autoplay={true}
            animationData={animationData}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <p className="font-semibold text-xl text-[#338F8C] text-center">
          {!nextClass && !existNextClass ? (
            <span className="font-bold text-[#F17C36]">
              Não há aulas agendadas.
            </span>
          ) : (
            <>
              Sua próxima aula será em
              <span className="font-bold text-[#F17C36]">
                {getNextClassStr()}.
              </span>
            </>
          )}
        </p>
      </div>
    </Layout>
  );
}
