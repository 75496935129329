import React, { useState } from "react";
import workout_1 from "../../../src/static/animations/workout_1.json";
import workout_2 from "../../../src/static/animations/workout_2.json";
import workout_3 from "../../../src/static/animations/workout_3.json";
import workout_4 from "../../../src/static/animations/workout_4.json";
import workout_5 from "../../../src/static/animations/workout_5.json";
import workout_6 from "../../../src/static/animations/workout_6.json";
import Layout from "./Layout";

import { Transition } from "@headlessui/react";
import Lottie from "lottie-react";

const animationData = [
  workout_1,
  workout_2,
  workout_3,
  workout_4,
  workout_5,
  workout_6,
];

const WaitingToJoinScreen = () => {
  const [animationIndex, setAnimationIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  const animationDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData[animationIndex],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center w-full h-full px-8">
        <h1 className="font-semibold text-2xl md:text-4xl text-[#F17C36] text-center">
          Aguardando todos os participantes
        </h1>
        <div className="w-1/2 md:w-80 md:h-80">
          <Transition
            appear
            show={showAnimation}
            as="div"
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Lottie
              loop={animationDefaultOptions.loop}
              autoplay={animationDefaultOptions.autoplay}
              animationData={animationDefaultOptions.animationData}
              rendererSettings={{
                preserveAspectRatio:
                  animationDefaultOptions.rendererSettings.preserveAspectRatio,
              }}
              onLoopComplete={() => {
                setShowAnimation(false);
                setTimeout(() => {
                  setAnimationIndex((prev) => {
                    if (prev === animationData.length - 1) {
                      return 0;
                    }

                    return prev + 1;
                  });

                  setShowAnimation(true);
                }, 500);
              }}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Transition>
        </div>
        <p className="font-semibold text-xl md:text-3xl text-[#338F8C] text-center">
          Sua aula será iniciada em breve.
        </p>
      </div>
    </Layout>
  );
};

export default WaitingToJoinScreen;
